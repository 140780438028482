<template>
  <div>
    <div>
      <div class="video-player" v-show="!isError">
        <video-player
          :options="playerOptions"
          :playsinline="playsinline"
          @canplay="onPlayerCanplay($event)"
          @canplaythrough="onPlayerCanplaythrough($event)"
          @ended="onPlayerEnded($event)"
          @loadeddata="onPlayerLoadeddata($event)"
          @pause="onPlayerPause($event)"
          @play="onPlayerPlay($event)"
          @playing="onPlayerPlaying($event)"
          @ready="playerReadied"
          @statechanged="playerStateChanged($event)"
          @timeupdate="onPlayerTimeupdate($event)"
          @waiting="onPlayerWaiting($event)"
          class="video-player-box vjs-big-play-centered"
          customEventName="customstatechangedeventname"
          ref="videoPlayer"
          v-if="isReady"
          webkit-playsinline
          x-webkit-airplay="allow"
          x5-playsinline
          x5-video-player-type="h5"
        ></video-player>
      </div>
      <div class="errorTip" v-show="isError">
        <p>视频出错了！</p>
      </div>
      <van-cell-group v-if="isReady">
        <van-cell :title="info.title">
          <template #right-icon>
            <span class="video-status" v-html="info.tips"></span>
          </template>
        </van-cell>
        <van-cell>
          <van-button :type="info.is_liked ? 'danger' : 'default'" @click="digg(info)" icon="good-job-o" size="small">{{info.diggtop || ''}}</van-button>
          <van-button :icon="info.favaid ? 'star' : 'star-o'" :type="info.favaid ? 'danger' : 'default'" @click="fava(info)" size="small"></van-button>
          <van-button @click="$router.push({name:'report', params:{id, classid}})" icon="warning-o" size="small">报错</van-button>
          <template #right-icon>
            <van-button @click="payfordownconfirm=true" icon="gem" size="small" type="danger" v-if="!info.ispayed">完整版 {{info.buyfen}} 点</van-button>
          </template>
        </van-cell>
      </van-cell-group>
      <van-cell-group title="所属资料" v-if="isReady">
        <van-cell :title="info.shop.title" @click="showInfo(info.shop)" is-link></van-cell>
      </van-cell-group>
    </div>
    <van-cell-group title="相关视频">
      <video-grid :list="info.otherlink" />
    </van-cell-group>
    <van-action-sheet title="播放完整版" v-model="payfordownconfirm">
      <h3 class="pt-20 tc">播放完整版将扣除您 {{info.buyfen_format}}</h3>
      <h3 class="pb-20 tc" v-if="isLogin">当前有 {{$store.state.user.userfen}} 点</h3>
      <h3 class="pb-20 tc" v-else>您需要先登陆</h3>
      <van-row class="mb-5" gutter="5">
        <van-col :span="8">
          <van-button @click="payfordownconfirm=false" block round>取消</van-button>
        </van-col>
        <van-col :span="16" v-if="!isLogin">
          <van-button @click="$router.push({name:'login'})" block plain round type="primary">登 陆</van-button>
        </van-col>
        <van-col :span="8" v-if="isLogin">
          <van-button @click="$router.push({name: 'buyfen'})" block plain round type="warning">获取点数</van-button>
        </van-col>
        <van-col :span="8" v-if="isLogin">
          <van-button @click="doPayfordown" block plain round type="primary">确定</van-button>
        </van-col>
      </van-row>
    </van-action-sheet>
  </div>
</template>

<script>
import { hasHistory } from "@/libs/tools";
import Vue from "vue";
import {
  Cell,
  Button,
  CellGroup,
  Row,
  Col,
  ActionSheet,
} from "vant";
Vue.use(Cell).use(CellGroup).use(Button).use(Row).use(Col).use(ActionSheet);
import wx from "weixin-js-sdk";
import "video.js/dist/video-js.css";
import { videoPlayer } from "vue-video-player";
import videoGrid from "../components/videoGrid";

export default {
  name: "play",
  components: { videoPlayer, videoGrid },
  props: ["id"],
  data() {
    return {
      isReady: false,
      isError: false,
      info: {},
      relateList: [],
      historyList: [],
      page_title: [],
      recommends: [],
      hot10: [],
      best10: [],
      new10: [],
      payfordownconfirm: false,
      playerOptions: {
        autoplay: true, //如果true,浏览器准备好时开始回放。
        muted: false,
        language: "zh-CN",
        aspectRatio: "16:9",
        playbackRates: [0.7, 1.0, 1.5, 2.0],
        fluid: true, // 当true时，Video.js player将拥有流体大小。换句话说，它将按比例缩放以适应其容器。
        sources: [
          {
            type: "video/mp4",
            src: "",
          },
        ],
        poster:
          "https://img.alicdn.com/imgextra/i4/6000000001186/O1CN01iYUBhZ1KdEJFtv1af_!!6000000001186-0-tbvideo.jpg",
        width: document.documentElement.clientWidth, //播放器宽度
        notSupportedMessage: "此视频暂无法播放，请稍后再试", //允许覆盖Video.js无法播放媒体源时显示的默认信息。
        "x5-video-player-type": "h5",
        controlBar: {
          timeDivider: true, // 时间分割线
          durationDisplay: true, // 区间显示
          remainingTimeDisplay: false, // 显示剩余时间
          fullscreenToggle: true, //全屏按钮
        },
      },
      player: null,
      lang: {
        "You aborted the media playback": "视频播放被终止",
        "A network error caused the media download to fail part-way.":
          "网络错误导致视频下载中途失败。",
        "The media could not be loaded, either because the server or network failed or because the format is not supported.":
          "视频因格式不支持或者服务器或网络的问题无法加载。",
        "The media playback was aborted due to a corruption problem or because the media used features your browser did not support.":
          "由于视频文件损坏或是该视频使用了你的浏览器不支持的功能，播放终止。",
        "No compatible source was found for this media.":
          "无法找到此视频兼容的源。",
      },
    };
  },
  computed: {
    playsinline() {
      var ua = navigator.userAgent.toLocaleLowerCase();
      //x5内核
      if (ua.match(/tencenttraveler/) != null || ua.match(/qqbrowse/) != null) {
        return false;
      } else {
        //ios端
        return true;
      }
    },
    changeParam() {
      return this.classid + "-" + this.id;
    },
  },
  methods: {
    getData() {
      this.$http.get("e/?m=v", { id: this.id }).then((res) => {
        if (res.code) {
          if (hasHistory()) {
            this.$router.go(-1);
          } else {
            this.$router.push({ name: "index" });
          }
        } else {
          const { info, recommends } = res.data;
          if (info.mid * 1 === 6) this.showInfo(info);
          this.$store.commit("setPageTitle", info.title);
          this.playerOptions.aspectRatio = info.aspectRatio;
          this.playerOptions.sources[0].src = info.path;
          this.playerOptions.poster = info.cover;
          this.info = info;
          this.recommends = recommends;
          this.isReady = true;
          wx.config(signpackage);
          const wxParam = {
            title: info.title, // 分享标题
            desc: "", // 分享描述
            imgUrl: info.titlepic || "http://75pop.com/images/no_picture.gif", // 分享图标
            type: "video", // 分享类型,music、video或link，不填默认为link
            dataUrl: info.path || "", // 如果type是music或video，则要提供数据链接，默认为空
          };
          this.wxReady(wx, wxParam);
        }
      });
    },
    doPayfordown() {
      this.$http.post("e/?m=kf", { id: this.id, c: 1 }).then((res) => {
        if (res.code === 0) {
          this.getData();
          this.payfordownconfirm = false;
        }
      });
    },
    onPlayerEnded(e) {},
    onPlayerWaiting(e) {},
    onPlayerPlaying(e) {},
    onPlayerLoadeddata(e) {},
    onPlayerTimeupdate(e) {},
    onPlayerCanplay(e) {},
    onPlayerCanplaythrough(e) {},
    // listen event
    onPlayerPlay(player) {},
    onPlayerPause(player) {
      console.log("player pause!");
    },
    // or listen state event
    playerStateChanged(playerCurrentState) {
      console.log("player current update state", playerCurrentState);
    },
    // player is ready
    playerReadied(player) {
      this.player = player;
      player.play();
      this.setPlayerProp();
    },
    setPlayerProp() {
      // 区分安卓和IOS系统，添加播放器属性
      var ua = navigator.userAgent.toLocaleLowerCase();
      var _video = document.querySelector("video");
      if (ua.match(/tencenttraveler/) != null || ua.match(/qqbrowse/) != null) {
        _video.setAttribute("x-webkit-airplay", true);
        _video.setAttribute("x5-playsinline", true);
        _video.setAttribute("webkit-playsinline", true);
        _video.setAttribute("playsinline", true);
      } else {
        //ios端
        _video.setAttribute("webkit-playsinline", true);
        _video.setAttribute("playsinline", true);
      }
    },
  },
  watch: {
    changeParam: {
      handler() {
        this.backtop();
        this.getData();
      },
      immediate: true,
    },
  },
  activated() {
    this.payfordownconfirm = false;
  },
};
</script>

<style scoped>
.panel-footer {
  text-align: right;
}
.panel-footer .van-icon {
  font-size: 20px;
}
</style>
